import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../../components/hero";
import { CaseList } from "../../../components/caseList.en";

import doodle from "../../../assets/images/services/strategy.svg";

const StrategyConception = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-strategy.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="service.strategy.link">
      <Seo
        title="Digital strategy and conception – Monday Consulting"
        description="Setting up a digital brand experience for your customers means picking the right strategy – and this is where our experts can offer a helping hand."
        keywords="Digital strategy, conception, consulting, requirements engineering"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Collaborative consulting"
        subline="Setting up a digital brand experience for your customers means picking the right strategy – and this is where our experts can offer a helping hand."
        section="Digital strategy & conception"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Digital strategy & conception"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Your goals – our approach<"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Your goals – our approach</h2>
            <p>
              Whatever the challenge you’re facing – from a website relaunch to
              a cloud migration or a brand-new brand – everything starts with
              the right digital strategy and a well-thought-out conceptual
              design for your backend IT. With Monday Consulting at your side,
              all of these challenges turn into work packages that we tackle
              together.
            </p>
            <p>
              We see ourselves as collaborative consultants, which means that
              your requirements and goals always form the starting point for our
              work. The market offers a wide choice of digital solutions for
              many different requirements: to draw up your shortlist, our
              experts help you define the criteria you need to ensure the
              success of your business. Defining these basic needs helps you to
              choose the right content management system and a matching content
              strategy.
            </p>
            <p>
              Our experts have been crafting tailor-made digital strategies for
              large and mid-sized enterprise clients since 2009. And each of
              these has been one-of-a-kind – because your needs and requirements
              as a business are as unique as you are. This is why we work with
              you to identify the perfect solution.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Digital strategy & conception in detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-requirementsengineering bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Requirements engineering
            </h3>
            <p>
              The cornerstone of our strategic planning is a comprehensive
              analysis: we gather requirements from your stakeholders and work
              with you to develop an optimal solution.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-digitalstrategy bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Digital strategy
            </h3>
            <p>
              We aim to achieve your success. Which is why we work to define a
              digital strategy that’s truly in line with your business goals.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-concept bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Conceptual design
            </h3>
            <p>
              You’re in safe hands when it comes to solution design and system
              architectures. Drawing on years of experience, our experts devise
              the ideal IT landscape for your needs.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-cms bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Content management systems
            </h3>
            <p>
              Not yet sure which CMS is right for you? We help you to find the
              right answer, and show you the ins and outs of the system that
              meets your needs.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="bgw,generali" />
      </div>

      <ContactPerson
        headline="How can we help you today?"
        text="After browsing our impressive portfolio of services, why not contact us to find out more? Advice and assistance is just a phone call away."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default StrategyConception;
